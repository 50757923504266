@import '../../assets/scss/variables.scss';

.pd-checkout-breadcrumb {
  display: grid;
  gap: $x1;
  position: relative;

  &__list {
    background: $bgOrange1;
    display: grid;
    gap: $x1;
    padding: $x1;
    padding: $x3;
    gap: $x3;
  }

  &__item {
    display: grid;

    .pd-button {
      justify-content: flex-start;
    }
  }

  &__shipping-strategy-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    gap: $x1;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $desktopBreakpoint) {
    &__shipping-strategy {
      position: absolute;
      bottom: $x1 * -1;
      right: 0;
      transform: translateY(100%);
      justify-self: flex-end;
    }

    &__list {
      padding: $x3;
      gap: $x3;
      grid-auto-flow: column;
      justify-content: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}