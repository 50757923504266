@import '../../assets/scss/variables.scss';

.pd-progress-bar {
  pointer-events: none;
  transition: $transition200;
  opacity: 0;
  display: grid;
  padding: $x1;
  align-items: center;
  background: rgba($white, 0.8);

  &__track {
    padding: 2px;
  }

  &__bar {
    transition: all 100ms ease-out;
    height: 4px;
  }

  &--active {
    opacity: 1;
  }

  &--navy {
    .pd-progress-bar__track {
      border: 1px solid $bgNavy1;
      background: rgba($bgNavy1, 0.1);
    }

    .pd-progress-bar__bar {
      background: rgba($bgNavy1, 0.8);
    }
  }

  &--cyan {
    .pd-progress-bar__track {
      border: 1px solid $bgCyan1;
      background: rgba($bgCyan1, 0.1);
    }

    .pd-progress-bar__bar {
      background: rgba($bgCyan1, 0.8);
    }
  }
}
