@import '../../assets/scss/variables.scss';

.pd-notifications {
  &__ks-update,
  &__reminder,
  &__custom-email,
  &__settings {
    display: grid;
    gap: $x3;

    .pd-button {
      justify-self: flex-start;
    }
  }
}
