@import '../../assets/scss/variables.scss';

.pd-tracking-numbers {
  &__item.pd-tpl-nav-content__item {
    display: grid;
    grid-auto-flow: row;
    justify-items: flex-start;
    border-bottom: 1px solid $gray1;
    padding-bottom: $x3;
    margin-bottom: $x3;
    cursor: default;
  }

  &__uploader {
    max-width: 230px;
  }

  &__box {
    display: grid;
    gap: $x3;
  }

  &__details {
    display: grid;
    gap: $x1;
    justify-content: flex-start;
  }

  &__full {
    margin: 0;
    padding: $x2;
    width: 96vw;
    height: 96vh;
    overflow-y: auto;
    overflow-x: auto;
    background: $gray1;
  }
}