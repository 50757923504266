@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';

.pd-ks-updates {
  display: grid;
  position: relative;

  &__slide {
    display: grid;
    justify-items: center;
  }

  &__container {
    display: grid;
  }

  &__figure {
    img {
      max-width: 100%;
      display: block;
    }
  }

  &__content {
    display: grid;
    gap: $x1;
    padding: $x3;
    justify-items: flex-start;

    .pd-text {
      @include max-lines(4);
    }
  }

  &__arrow {
    display: none;
  }

  &__dots {
    position: absolute;
    top: $x2;
    right: $x2;
    display: grid;
    gap: $x2;
    justify-items: center;
  }

  &__dot {
    appearance: none;
    border: 0;
    width: $x2;
    height: $x2;
    outline: 0;
    padding: 0;
    border-radius: $x2;
    overflow: hidden;
    background: red;
    transition: $transition200;
    background: rgba($bgNavy1, 0.2);
    cursor: pointer;

    &--active {
      background: $bgNavy1;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (max-width: $ipadBreakpoint - 1) {
    .pd-container {
      padding: 0;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__container {
      grid-template-columns: 5fr 3fr 2fr;
      gap: $x5;
      align-items: center;
    }

    &__content {
      align-content: center;
      padding: 0;

      .pd-text {
        @include max-lines(5);
      }
    }

    &__arrow {
      display: block;
      transition: $transition200;
      position: absolute;
      top: 0;
      height: 100%;
      width: calc(#{$x3} + 20px);
      padding: $x2;
      fill: $bgNavy1;
      cursor: pointer;

      &:hover {
        background: rgba($bgNavy1, 0.2);
      }

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }

    &__dots {
      top: initial;
      bottom: $x2;
      right: initial;
      left: 50%;
      grid-auto-flow: column;
    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}