@import '../../assets/scss/variables.scss';

.pd-switch {
  display: grid;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 32px;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--labeled {
    grid-template-columns: 32px auto;
    gap: $x1;
    width: auto;
  }

  &__element {
    height: 18px;
    position: relative;

    &:before {
      content: '';
      transition: $transition200;
      box-shadow: $boxShadow;
      border-radius: 18px;
      display: block;
      width: 18px;
      height: 18px;
      background: white;
      z-index: 2;
      position: absolute;
      left: 0;
    }

    &:after {
      content: '';
      transition: $transition200;
      border-radius: $x1;
      position: absolute;
      top: 15%;
      left: 0;
      width: 100%;
      height: 70%;
      background: rgba($bgNavy1, 0.2);
      z-index: 1;
    }
  }

  &--checked {
    .pd-switch__element {
      &:before {
        background: $bgNavy1;
        left: 100%;
        transform: translateX(-100%);
      }

      &:after {
        background: rgba($bgNavy1, 0.5);
      }
    }
  }
}
