@import "../../assets/scss/variables.scss";

.pd-tip {
  padding: $x2;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $x2;

  &--success {
    border: 1px solid $dGreen;
    color: $dGreen;
    background: rgba($dGreen, 0.1);

    .pd-icon {
      fill: $dGreen;
    }
  }

  &--warning {
    border: 1px solid $dYellow;
    color: darken($dYellow, 10%);
    background: rgba($dYellow, 0.1);

    .pd-icon {
      fill: darken($dYellow, 10%);
      transform: rotate(180deg);
    }
  }

  &--error {
    border: 1px solid $dRed;
    color: $dRed;
    background: rgba($dRed, 0.1);

    .pd-icon {
      fill: $dRed;
      transform: rotate(180deg);
    }
  }

  &--info {
    border: 1px solid $dCyan;
    color: $dCyan;
    background: rgba($dCyan, 0.1);

    .pd-icon {
      fill: $dCyan;
    }
  }

  &--micro {
    padding: $x1/2;
    border: 0;
    gap: $x1;
    justify-self: flex-start;
    border-radius: $x1;

    .pd-icon {
      margin-top: 2px;
      width: 14px;
    }
  }
}
