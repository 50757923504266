@import '../../assets/scss/variables.scss';

.pd-pledge-search {
  display: grid;
  align-content: flex-start;
  gap: $x3;
  padding: $x3;
  background: $white;
  box-shadow: $boxShadow;

  &__form {
    display: grid;
    gap: $x1;
  }

  &__field-group {
    display: grid;
    grid-auto-flow: column;
    gap: $x1;
  }

  &__results {
    display: grid;
    gap: $x1;
    align-content: flex-start;
  }

  .pd-text-error {
    color: $dRed;
  }
}
