@import '../../assets/scss/variables.scss';

.pd-checkout-shipping-strategy-selector {
  width: 90vw;
  display: grid;
  gap: $x3;

  &__header {
    display: grid;
    gap: $x1;
  }

  &__options {
    display: grid;
    gap: $x3 * 2;
  }

  &__option {
    display: grid;
    gap: $x3;
    padding: $x3;
    background: $gray1;
    border: 1px solid $bgNavy1;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    width: 600px;

    &__options {
      transition: $transition200;

      &:hover {
        .pd-checkout-shipping-strategy-selector__option {
          opacity: .4;
        }
      }
    }

    &__option {
      transition: $transition200;
      transform: scale(1);

      &:hover {
        opacity: 1 !important;
        transform: scale(1.03);
      }

      .pd-button {
        justify-self: start;
      }
    }
  }
}