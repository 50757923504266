@import '../../assets/scss/variables.scss';

.pd-unlock-pledge {
  display: grid;
  gap: $x3;
  align-items: flex-start;

  &__main {
    display: grid;
    gap: $x2;
  }

  &__form {
    display: grid;
    gap: $x1;

    .pd-button {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 1fr 1.5fr;
    align-items: flex-start;

    &__contents {
      grid-column: 1/3;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
