@import './variables';

@mixin list-hover($itemClassName) {
  &:hover {
    #{$itemClassName} {
      opacity: 0.6;
    }
  }

  #{$itemClassName} {
    transition: $transition200;

    &:hover {
      opacity: 1 !important;
    }
  }
}

@mixin max-lines($lines: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@function rawColor($color, $background: #ffffff) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);

  @if ($percent < 100%) {
    @return $solid-color;
  }

  @return $color;
}