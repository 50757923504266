@import '../../assets/scss/variables.scss';

.pd-rewards-form {
  &__main {
    gap: $x3;

    &--bundle {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        'name name'
        'desc desc'
        'included included'
        'price amount'
        'per-user min-per-user'
        'multiplier multiplier'
        'limited-add-ons limited-add-ons'
        'threshold threshold'
        'ks-id ks-id'
        'default-bundle default-bundle'
        'late-confirm late-confirm'
        'late-pledge late-pledge'
        'extra extra'
        'required required'
        'upgrade upgrade'
        'downgrade downgrade'
        'image image'
        'products products'
        'cart-warning cart-warning'
        'actions actions'
      ;
    }

    &--add-on {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        'name name'
        'desc desc'
        'included included'
        'price amount'
        'per-user per-bundle'
        'min-per-user ks-id'
        'threshold threshold'
        'late-confirm late-confirm'
        'late-pledge late-pledge'
        'limited-by limited-by'
        'required required'
        'image image'
        'products products'
        'cart-warning cart-warning'
        'actions actions'
      ;
    }
  }


  &__field-name {
    grid-area: name;
  }

  &__field-description {
    grid-area: desc;
  }

  &__field-included {
    grid-area: included;
  }

  &__field-price {
    grid-area: price;
  }

  &__field-amount {
    grid-area: amount;
  }

  &__field-per-user {
    grid-area: per-user;
  }

  &__field-min-per-user {
    grid-area: min-per-user;
  }

  &__field-per-bundle {
    grid-area: per-bundle;
  }

  &__field-upgrade {
    grid-area: upgrade;
  }

  &__field-downgrade {
    grid-area: downgrade;
  }

  &__field-required {
    grid-area: required;
  }

  &__field-multiplier {
    grid-area: multiplier;
  }

  &__field-extra {
    grid-area: extra;
  }

  &__field-default-bundle {
    grid-area: default-bundle;
  }

  &__field-exclusive {
    grid-area: exclusive;
  }

  &__field-late-confirm {
    grid-area: late-confirm;
  }

  &__field-late-pledge {
    grid-area: late-pledge;
  }

  &__field-limited-add-ons {
    grid-area: limited-add-ons;
  }

  &__field-threshold {
    grid-area: threshold;
  }

  &__field-ks-id {
    grid-area: ks-id;
  }

  &__field-limited-by {
    grid-area: limited-by;
  }

  &__field-cart-warning {
    grid-area: cart-warning;
  }

  &__image {
    grid-area: image;
  }

  &__products {
    grid-area: products;

    ul {
      border: 1px solid $gray1;
    }
  }

  &__product {
    transition: transition200;
    padding: $x2;
    border-bottom: 1px solid $gray1;
    display: grid;
    grid-template-columns: auto 60px;
    gap: $x1;

    &--included {
      background: rgba($bgCyan1, 0.3);
    }
  }

  &__actions {
    grid-area: actions;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__main {
      grid-template-rows: auto;
      grid-template-columns: repeat(9, 1fr);

      &--bundle {
        grid-template-areas:
          'name name name name name image image image image'
          'desc desc desc desc desc image image image image'
          'included included included included included image image image image'
          'price amount amount per-user per-user min-per-user min-per-user ks-id ks-id'
          'limited-add-ons limited-add-ons limited-add-ons threshold threshold threshold multiplier multiplier multiplier'
          'default-bundle default-bundle default-bundle default-bundle required required required required required'
          'extra extra extra extra required required required required required'
          'late-confirm late-confirm late-confirm late-confirm required required required required required'
          'late-pledge late-pledge late-pledge late-pledge required required required required required'
          'upgrade upgrade upgrade upgrade required required required required required'
          'downgrade downgrade downgrade downgrade required required required required required'
          'products products products products products products products products products'
          'cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning'
          'actions actions actions actions actions actions actions actions actions'
        ;
      }

      &--add-on {
        grid-template-areas:
          'name name name name name image image image image'
          'desc desc desc desc desc image image image image'
          'included included included included included image image image image'
          'price amount amount per-user per-user per-bundle per-bundle min-per-user min-per-user'
          'ks-id ks-id  threshold threshold threshold required required required required'
          'late-confirm late-confirm late-confirm late-confirm late-confirm required required required required'
          'limited-by limited-by limited-by limited-by limited-by required required required required'
          'late-pledge late-pledge late-pledge late-pledge late-pledge required required required required'
          'products products products products products products products products products'
          'cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning cart-warning'
          'actions actions actions actions actions actions actions actions actions'
        ;
      }
    }

    &__field-required {
      grid-template-rows: auto 1fr;
    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}