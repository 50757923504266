@import '../../assets/scss/variables.scss';

.pd-collaborators-list {
  display: grid;
  gap: $x2;

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__table {
      &__user-th {
        width: 160px;
      }
    }
  }
}
