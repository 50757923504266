@import "../../assets/scss/variables.scss";

.pd-backer-report-filter {
  display: grid;
  gap: $x2;

  .pd-select {
    width: 100%;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 1fr 1fr 2fr 1.3fr;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
