@import "../../assets/scss/variables.scss";

.pd-table {
  box-shadow: $boxShadow;
  width: 100%;

  &--x-scroll {
    overflow-x: auto;

    .pd-table__table {
      white-space: nowrap;
    }
  }

  &__table {
    border: 1px solid $gray1;
    table-layout: fixed;
    border-spacing: 0;
    background: $white;
    min-width: 100%;
  }

  &__loading {
    text-align: left;
    padding: $x1;
  }

  &__tr {
    transition: $transition200;
    vertical-align: top;

    &:nth-child(odd) {
      background: $gray1;
    }

    &:hover {
      background: $gray2;
    }
  }

  &__th,
  &__td {
    text-align: left;
    padding: $x2 $x1;

    &--clicable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__td a:not(.pd-button--text-inline) {
    display: flex;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {

    &__th,
    &__td,
    &__loading {
      padding: $x2;
    }
  }
}