@import '../../assets/scss/variables.scss';

.pd-discount-pledge {
  display: grid;
  gap: $x3;

  &__main {
    display: grid;
    gap: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadLandscapeBreakpoint) {
    grid-template-columns: 1fr 1.5fr;
    align-items: flex-start;

    &__main {
      grid-column: 2/3;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 0;
      align-content: flex-start;

      .pd-field {
        &:nth-child(1) {
          grid-column: 1/2;
        }

        &:nth-child(2) {
          grid-column: 2/5;
        }
      }

      .pd-button {
        grid-column: 1/3;
        justify-self: flex-start;
      }
    }

    &__contents {
      grid-column: 1/3;
    }
  }
}
