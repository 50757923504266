@import "../../assets/scss/variables.scss";

.pd-checkout-product-contents {
  display: grid;
  gap: $x2;
  padding: $x3 $x3 0;
  max-width: 100%;
  overflow: auto;

  &:after {
    content: "";
    display: block;
    height: $x1;
    width: 100%;
  }

  &__image {
    max-width: 100%;
    display: block;
  }

  &__description {
    display: grid;
    gap: $x2;
  }

  &__items {
    display: grid;
    gap: 0;
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: $x1;
  }

  &__amount {
    color: $dSilver;
  }

  &__notes {
    color: $dSilver;
    white-space: pre-line;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    width: 720px;

    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}