@import "../../assets/scss/variables.scss";

.pd-checkout-project-info {
  display: grid;
  gap: $x2;
  align-items: flex-start;

  &__header {
    display: grid;
    gap: $x3;
    align-content: flex-start;

    .pd-text {
      white-space: pre-line;
    }
  }

  &__content {
    display: grid;
    gap: $x2;
  }

  &__contents {
    display: grid;
    gap: $x2;
  }

  &__video {
    position: relative;

    img {
      width: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__figure {
    img {
      max-width: 100%;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 1fr 1.4fr;
    align-content: flex-start;
    gap: $x4;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
