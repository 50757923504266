@import '../../assets/scss/variables.scss';

.pd-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__button {
    margin: 0 ($x1/2) $x1 ($x1/2);

    &--active {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
