@import '../../assets/scss/variables.scss';

.pd-checkout-shipping {
  &__info, &__current {
    display: grid;
    gap: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__info .pd-button, &__save {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}