@import '../../assets/scss/variables.scss';

.pd-checkout-summary {
  display: grid;
  gap: $x3;
  transform: translate3d(0, 0, 0);
  padding: $x3;
  border-left: 1px solid $gray1;
  background: $white;
  box-shadow: $boxShadow;

  &__table {
    border-spacing: 0;
    border-collapse: separate;
    max-width: 100%;
    width: 100%;

    thead {
      tr {
        th {
          padding: $x2 0;
        }

        th:nth-child(1),
        th:nth-child(3) {
          text-align: left;
        }

        th:nth-child(2),
        th:nth-child(4) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 1px solid $gray1;
          padding: $x2 $x1;
        }

        td:nth-child(1) {
          text-align: left;
        }

        td:nth-child(2) {
          width: 74px;
          text-align: center;
        }

        td:nth-child(3) {
          width: 86px;
          text-align: left;
        }
      }
    }
  }

  &__row {
    vertical-align: text-top;

    td {
      position: relative;

      &:before {
        transition: $transition200;
        content: '';
        background: $white;
        opacity: 0;
        display: block;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--processing {
      td:before {
        opacity: 0.8;
      }
    }
  }

  &__disclaimers {
    display: grid;
    justify-content: flex-start;
    gap: $x1;
  }

  &__cell {
    &--positive {
      color: $dGreen;
    }

    &--negative {
      color: $dRed;
    }
  }

  &__hidden {
    opacity: 0.2;
  }

  &__accordion-heading {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: $x1;
  }

  &__icon-lock.pd-icon {
    fill: $dCyan;
    width: 13px;
  }

  &__scroll-indicator {
    opacity: 0;
  }

  &__contents {
    padding-top: $x1;
  }

  &__content-locked-disclaimer {
    margin-top: $x2;
  }

  &__content {
    display: grid;
    gap: $x1;
    grid-template-columns: auto 1fr;
    color: $dSilver;
  }

  &__expand-indicator.pd-icon {
    width: 10px;
    fill: $bgCyan1;
  }



  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    padding: $x4;
    max-height: calc(100vh - #{$headerDesktopHeight + $x5});
    overflow: auto;
    position: relative;

    &__scroll-indicator {
      transition: $transition200;
      animation: shake-vertical 3s ease-in-out infinite both;
      position: sticky;
      bottom: (($x4 - $x2) * -1);
      left: 50%;
      opacity: 0;
      pointer-events: none;

      &--visible {
        opacity: 1;
      }

      > * {
        fill: $bgCyan1;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}