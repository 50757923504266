@import '../../assets/scss/variables.scss';

.pd-new-zipcode-range-form {
  &__group {
    display: grid;
    gap: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: repeat(2, 1fr);

    &__country,
    &__province {
      grid-column: 1/3;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
