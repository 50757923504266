@import '../../assets/scss/variables.scss';

.pd-pledge-order-contents {
  display: grid;
  gap: $x2;

  &__table {

    &__units-th,
    &__units-td {
      width: 65px;
      text-align: center;
    }

    &__price-th {
      width: 80px;
    }

    &__total-th {
      width: 100px;
    }
  }

  &__expand-indicator.pd-icon {
    width: 10px;
    fill: $bgCyan1;
  }

  &__products {
    padding-top: $x1;
    color: $dSilver;
  }
}