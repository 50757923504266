@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';

.pd-available-projects-list {
  background: $bgOrange1;
  background: linear-gradient(180deg, $bgOrange1 260px, transparent 260px);
  padding: $x4 0;

  &__container {
    display: grid;
    gap: $x4;
  }

  &__list {
    display: grid;
    gap: $x3;
  }

  &__item {
    display: grid;
    box-shadow: $boxShadow;
    background: $white;

    img {
      max-width: 100%;
    }
  }

  &__link {
    display: grid;
    align-content: flex-start;

    &:hover {
      text-decoration: none;
    }
  }

  &__wrapper {
    display: grid;
    gap: $x1;
    padding: $x3;

    .pd-text {
      @include max-lines(3);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @media screen and (min-width: $ipadBreakpoint) {
    padding: $x5 0;

    &__container {
      gap: $x5;
    }

    &__list {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $x4;
    }

    &__item {
      transition: $transition200;
      transform: scale(1);

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
