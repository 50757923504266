@import '../../assets/scss/variables.scss';

.pd-dashboard-ks-updates {

  &__box {
    display: grid;
    gap: $x3;
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__box {
      grid-template-columns: 1.3fr 1fr;
      grid-template-rows: repeat(auto, 5);
      align-content: flex-start;
    }

    &__title,
    &__headline,
    &__url,
    &__active {
      grid-column: 1/2;
    }

    &__title {
      grid-row: 1/2;
    }

    &__headline {
      grid-row: 2/3;
    }

    &__url {
      grid-row: 3/4;
    }

    &__active {
      grid-row: 4/5;
    }

    &__image {
      grid-column: 2/3;
      grid-row: 1/5;
    }

    &__actions {
      grid-column: 1/3;
      grid-row: 5/6;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}