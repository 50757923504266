@import '../../assets/scss/variables.scss';

.pd-shipping-tables {
  display: grid;
  gap: $x3;

  &__form {
    display: grid;
    gap: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: repeat(2, 1fr);

    &__new-cta {
      grid-column: 1/3;
      justify-self: flex-end;
    }

    &__form {
      grid-template-columns: 1fr auto auto;
      align-items: flex-end;
      row-gap: $x1;
      gap: $x2;

      > .pd-label {
        grid-column: 1/4;
      }
    }

    &__simulator {
      grid-column: 1/3;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
