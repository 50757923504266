@import '../../assets/scss/variables.scss';

.pd-checkout-tracking-number-info {
  display: grid;
  gap: $x2;
  align-content: flex-start;
  justify-items: flex-start;

  &__list {
    display: grid;
    gap: $x2;

    li:not(:last-child) {
      padding-bottom: $x2;
      border-bottom: 1px solid $gray1;
    }
  }

}