@import "../../assets/scss/variables.scss";

.pd-profile-form {
  // align-content: flex-start;

  // .pd-profile-section__content {
  // grid-template-columns: auto 1fr;
  // align-items: flex-start;
  // gap: $x3;
  // }

  // &__avatar {
  //   display: grid;
  //   gap: $x3;

  //   input[type='file'] {
  //     display: none;
  //   }

  //   .pd-button {
  //     justify-self: flex-start;
  //   }
  // }

  // &__image {
  //   position: relative;
  // }

  // &__progress {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  &__main {
    display: grid;
    gap: $x3;
    // align-content: flex-start;
    // background: #ccc;

    input {
      width: 100%;
    }

    button {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @media screen and (min-width: $ipadBreakpoint) {
    align-content: flex-start;

    // .pd-profile-section__content {
    //   // gap: $x4;
    // }
  }
}
