.pd-tpl-dashboard {
  display: grid;
  gap: $x3;

  &.pd-page {
    padding-top: $x3;
  }

  &__header {
    display: grid;
    gap: $x2;
  }

  &__breadcrumb {
    display: grid;
    gap: $x1;
  }

  &__breadcrumb-items {
    display: grid;
    gap: $x1;
  }

  &__status {
    justify-self: flex-start;
  }

  &__nav {
    display: grid;
    gap: $x3;
    padding-bottom: $x2;
  }

  &__nav-list {
    display: grid;
    gap: $x1;
    align-content: flex-start;
  }

  &__nav-item {
    display: grid;
    justify-content: flex-start;
  }

  &__publish-cta {
    display: grid;
    gap: $x2;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    gap: $x4;
    grid-template-columns: auto 230px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header nav'
      'container nav';

    &--without-nav {
      grid-template: 'header' auto 'container ' 1fr / 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'header'
        'container';
    }

    &.pd-page {
      padding-top: $x4;
    }

    &__header {
      grid-area: header;
      grid-auto-flow: column;
      grid-template-columns: 1fr auto;
      align-items: flex-start;
      gap: $x3;
    }

    &__breadcrumb {
      gap: $x2;
    }

    &__breadcrumb-items {
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: $x2;

      > * {
        &:after {
          content: '»';
          display: inline-block;
          margin-left: $x2;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    &__status {
      justify-self: flex-end;
    }

    &__nav {
      grid-area: nav;
      border-bottom: 0;
      padding-bottom: 0;
      gap: $x4;
      align-content: flex-start;
      align-self: flex-start;
      position: sticky;
      top: -10px;
    }

    &__publish-cta {
      justify-self: flex-start;
      display: grid;
      justify-items: flex-start;
      gap: $x2;
    }

    &__container {
      grid-area: container;
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-tpl-nav-content {
  display: grid;
  gap: $x3;

  &__nav,
  &__main {
    display: grid;
    gap: $x3;
    align-content: flex-start;
  }

  &__nav {
    gap: $x2;
    justify-items: flex-start;
    user-select: none;
  }

  &__box {
    position: relative;
    display: grid;
    padding: $x3;
    background: $white;
    box-shadow: $boxShadow;
  }

  &__required {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-150%);
  }

  &__list {
    display: grid;
    justify-self: stretch;
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: $x2;
    cursor: pointer;
    padding: $x1 $x2 $x1 0;

    &:hover {
      > span {
        text-decoration: underline;
      }
    }

    &--active {
      font-weight: 700;
      background: $bgCyan1;
      color: $white;
      padding: $x1 $x2;

      .pd-icon {
        fill: $white;
      }

      .pd-tip {
        color: $white;
      }

      &:hover {
        > span {
          text-decoration: none;
        }
      }
    }
  }

  &__sort {
    cursor: grabbing;
  }

  &__fields {
    display: grid;
    gap: $x3;
    align-items: flex-start;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  &__actions-wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: $x2;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 1fr 3fr;
    gap: $x4;

    &__main {
      border-top: 0;
    }

    &__nav {
      border-right: 1px solid $gray1;
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-tpl-lightbox-form {
  width: 500px;
  display: grid;
  gap: $x3;

  &__required {
    position: absolute;
    bottom: $x3;
    right: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__submit {
      justify-self: flex-start;
    }
  }
}
