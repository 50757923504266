@import "../../assets/scss/variables.scss";

.pd-transaction-details {
  display: grid;
  gap: $x3;

  &__overview {
    display: grid;
    gap: $x2;
    align-content: flex-start;
  }

  &__refunds {
    display: grid;
    gap: $x2;
    align-content: flex-start;
  }

  &__table__reason-td {
    display: grid;
    gap: $x1;

    .pd-tip {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template:
      "overview  refunds"auto "form      refunds"auto / 1fr 2fr;
    column-gap: $x4;

    &__overview {
      grid-area: overview;
    }

    &__refund-form {
      grid-area: form;
    }

    &__refunds {
      grid-area: refunds;
    }

    &__table {
      th:nth-child(1) {
        width: 150px;
      }

      th:nth-child(2) {
        width: 90px;
      }

      th:nth-child(3) {
        width: 70px;
      }
    }
  }
}