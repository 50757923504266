@keyframes shake-vertical {

  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateY(-5px);
  }

  20%,
  40%,
  60% {
    transform: translateY(5px);
  }

  80% {
    transform: translateY(3.4px);
  }

  90% {
    transform: translateY(-3.4px);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-scroll-panel {
  overflow-x: auto;

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 1px;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-icon {
  width: 20px;
  fill: $gray4;

  &--social {
    width: 32px;
    background: $white;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-white-box {
  padding: $x3;
  background: $white;
  box-shadow: $boxShadow;

  &--no-padding {
    padding: 0;
  }
}