@import '../../assets/scss/variables.scss';

.pd-dashboard-user-index {
  display: grid;
  gap: $x4;

  &__overview,
  &__pledges {
    display: grid;
    gap: $x2;
  }

  &__table {
    &__id-th {
      width: 55px;
    }

    &__state-th {
      width: 60px;
    }

    &__date-th {
      width: 55px;
    }

    &__total-th {
      width: 60px;
    }
  }

  .large {
    display: none;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadLandscapeBreakpoint) {
    .short {
      display: none;
    }

    .large {
      display: inline;
    }

    &__table {
      &__date-th {
        width: 160px;
      }

      &__total-th {
        width: 100px;
      }

      &__state-th {
        width: 120px;
      }
    }
  }
}
