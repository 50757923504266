@import '../../assets/scss/variables.scss';

.pd-shipping-form {
  display: grid;
  gap: $x3;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'country'
    'fieldset'
    'po-box'
    'submit'
    'required'
  ;

  &__country {
    grid-area: country;
  }

  &__fieldset {
    grid-area: fieldset;

    &[disabled] {
      opacity: .3;
    }
  }

  &__po-box {
    grid-area: po-box;
  }

  &__submit {
    grid-area: submit;
  }

  &__required {
    grid-area: required;
    justify-self: flex-end;
  }

  &__secondary {
    border-top: 1px solid $gray1;
    padding-top: $x3;
    display: grid;
    gap: $x3;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'name name'
      'phone taxpayer-id'
      'address-line-1 address-line-1'
      'address-line-2 address-line-2'
      'postal-code postal-code'
      'complement complement'
      'province province'
      'city city'
    ;
  }

  &__name {
    grid-area: name;
  }

  &__phone {
    grid-area: phone;
  }

  &__taxpayer-id {
    grid-area: taxpayer-id;
  }

  &__address-line-1 {
    grid-area: address-line-1;
  }

  &__address-line-2 {
    grid-area: address-line-2;
  }

  &__postal-code {
    grid-area: postal-code;
  }

  &__complement {
    grid-area: complement;
  }

  &__province {
    grid-area: province;
  }

  &__city {
    grid-area: city;
  }

  &__alternate {
    grid-column: 1/3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'country country'
      'fieldset fieldset'
      'po-box po-box'
      'submit required'
    ;

    &__secondary {
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
        'name name name name phone phone taxpayer-id taxpayer-id'
        'address-line-1 address-line-1 address-line-1 address-line-1 address-line-2 address-line-2 address-line-2 address-line-2'
        'postal-code postal-code postal-code complement complement complement complement complement'
        'province province province province city city city city'
      ;
    }

    &__alternate {
      grid-column: 1/9;
    }

    &__submit {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}