@import '../../assets/scss/variables.scss';

.pd-styleguide {
  display: grid;
  grid-auto-flow: row;
  gap: $x4;
  width: 100%;

  &.pd-page {
    padding-top: $x4;
  }

  &__section {
    display: grid;
    grid-auto-flow: row;
    gap: $x3;
    border-bottom: 1px solid $gray2;
    padding-bottom: $x4;
    max-width: 100%;

    &:last-child {
      border: 0;
    }
  }

  &__table-sample {

    &__age-th,
    &__age-td {
      width: 60px;
      text-align: center;
    }

    &__cost-th,
    &__cost-td {
      width: 120px;
      text-align: center;
    }

    &__date-th,
    &__date-td {
      width: 160px;
    }
  }

  &__decoder {
    max-width: 300px;
    justify-self: center;
  }

  &__buttons,
  &__disclaimers,
  &__lightboxes,
  &__tips {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    justify-items: center;
    gap: $x3;

    @media screen and (min-width: $ipadBreakpoint) {
      grid-auto-flow: column;
    }
  }

  &__progress {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    justify-items: center;
    gap: $x3;

    .pd-progress-bar {
      width: 200px;
    }
  }

  &__lightbox-sample-1,
  &__lightbox-sample-2,
  &__lightbox-sample-3 {
    display: grid;
    align-content: flex-start;
    gap: $x3;
  }

  &__lightbox-sample-1 {
    width: 280px;
    padding: $x3;
  }

  &__lightbox-sample-2 {
    width: 480px;
    padding: $x3;
  }

  &__lightbox-sample-3 {
    width: 640px;
    height: 480px;
    background: #ccc;
    padding: $x3;
  }

  &__pagination {
    display: grid;
    gap: $x3;
  }

  &__date-time-field {
    display: grid;
    gap: $x3;

    &__formik {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: $x1;
    }
  }
}