@import '../../assets/scss/variables.scss';

.pd-dashboard-project-index {
  display: grid;
  gap: $x3;

  &__module {
    display: grid;
    gap: $x3;
  }

  &__columns,
  &__full {
    display: grid;
    gap: $x3;
    padding: $x3 0;
  }

  &__columns {
    .pd-table {

      &__td,
      &__th {
        &:nth-child(2) {
          width: 70px;
          text-align: center;
        }
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: repeat(2, 1fr);

    &__panel {
      grid-column: 1/3;
    }

    &__columns {
      grid-template-columns: 1fr 1.3fr 1.7fr;
      align-items: flex-start;

      .pd-title-3 {
        grid-column: 1/4;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}