@import '../../assets/scss/variables.scss';

.pd-checkout-cart-warning-lightbox {
  display: grid;
  gap: $x2;
  justify-items: flex-start;
  max-width: 420px;

  .pd-button {
    justify-self: flex-end;
  }
}