@import '../../assets/scss/variables.scss';

.pd-shipping-table-editor {
  transition: $transition200;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: $x3;

  &.pd-page {
    padding-top: $x3 + $x3;
  }

  &--saving {
    pointer-events: none;

    button,
    input,
    select {
      opacity: 0.4;
    }
  }

  &__header {
    display: grid;
    grid-gap: $x2;
  }

  &__nav {
    display: grid;
    gap: $x1;
  }

  &__new-entities {
    margin-top: $x3;
    padding-top: $x3;
    border-top: 1px solid $gray2;
    display: grid;
    gap: $x1;
  }

  &__info {
    justify-self: flex-start;
  }

  &__scrollpanel {
    overflow-x: auto;
    background: $white;
    box-shadow: $boxShadow;
    justify-self: flex-start;
    max-width: 100%;
  }

  &__table {
    border: 0;
    border-spacing: 1px;
    border-collapse: separate;
  }

  &__tr {
    transition: $transition200;

    &:hover {
      background: $gray1;

      input[type='text'] {
        background: $gray1;
      }
    }

    &--lv1 {
      td:first-child {
        padding-left: $x3 * 1.5;
      }
    }

    &--lv2 {
      td:first-child {
        padding-left: $x3 * 3;
      }
    }

    &--lv3 {
      td:first-child {
        padding-left: $x3 * 4.5;
      }
    }
  }

  &__td,
  &__th {

    .pd-textfield,
    .pd-select {
      width: 100%;
    }

    .pd-button {
      width: 85px;
    }
  }

  &__th {
    background: $gray2;
    padding: $x1;
  }

  &__th-selector,
  &__td-title {
    min-width: 200px;
    padding: $x1;
  }

  &__th-action,
  &__td-action {
    width: 60px;

    .pd-button {
      width: 100%;
    }
  }

  &__th-fields,
  &__td-fields {
    width: 90px;
  }

  &__th-range,
  &__td-range {
    display: grid;
    grid-gap: $x1;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    .pd-textfield {
      width: 40px;
      text-align: center;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @media screen and (min-width: $ipadBreakpoint) {
    &.pd-page {
      padding-top: $x4 + $x3;
    }

    &__header {
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
    }

    &__nav {
      gap: $x2;
      grid-auto-flow: column;
      justify-content: flex-start;
    }

    &__new-entities {
      gap: $x2;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
      margin-left: $x2;
      padding-left: $x2;
      border-left: 1px solid $gray2;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}