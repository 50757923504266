@import '../../assets/scss/variables.scss';

.pd-products-form {
  &__main {
    gap: $x3;
  }

  &__fields {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'name name'
      'tariff-code tariff-code'
      'threshold threshold'
      'stock-limit slip'
      'sku wave'
      'wi le'
      'he we';
  }

  &__field-name {
    grid-area: name;
  }

  &__field-sku {
    grid-area: sku;
  }

  &__field-threshold {
    grid-area: threshold;
  }

  &__field-stock-limit {
    grid-area: stock-limit;
  }

  &__field-wave {
    grid-area: wave;
  }

  &__field-width {
    grid-area: wi;
  }

  &__field-height {
    grid-area: he;
  }

  &__field-weight {
    grid-area: we;
  }

  &__field-length {
    grid-area: le;
  }

  &__field-slip {
    grid-area: slip;
  }

  &__field-production {
    grid-area: prod;
  }

  &__field-tariff-code {
    grid-area: tariff-code;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @media screen and (min-width: $ipadBreakpoint) {
    &__fields {
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas:
        'name name name tariff-code tariff-code'
        'stock-limit threshold threshold sku slip'
        'we wi le he wave';
    }
  }
}
