@import '../../assets/scss/variables.scss';

.pd-new-project-form {
  padding: $x3;
  width: 400px;
  max-width: 100%;

  &__main {
    display: grid;
    gap: $x3;
  }

  &__nav {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }
}
