@import '../../assets/scss/variables.scss';

.pd-checkout-canceled-disclaimer {
  display: grid;
  gap: $x3;
  align-content: flex-start;
  justify-items: flex-start;

  .pd-text .pd-button--text {
    display: inline;
  }
}
