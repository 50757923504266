@import '../../assets/scss/variables.scss';

.pd-accordion {
  &__heading {
    display: grid;
    gap: $x1;
    grid-auto-flow: column;
    justify-content: flex-start;
    cursor: pointer;
    user-select: none;
    align-items: center;
  }

  &__indicator90, &__indicator180 {
    transition: $transition200;
    transform: rotate(0deg);
  }

  &__content {
    transition: $transition200;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &--expanded {
    .pd-accordion {
      &__content {
        max-height: 100vh;
        opacity: 1;
        pointer-events: all;
      }

      &__indicator90 {
        transform: rotate(90deg);
      }

      &__indicator180 {
        transform: rotate(180deg);
      }
    }
  }
}