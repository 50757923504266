* {
  box-sizing: border-box;
}

body {
  // font-family: $titillium;
  // font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bgGray1;
}

#root,
body,
html {
  height: 100%;
}

blockquote,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
ol,
p,
ul,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
th,
dt,
strong {
  font-weight: 500;
}

li,
dd,
main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}