@import "../../assets/scss/variables.scss";

.pd-checkout-payment-options {
  &__wrapper {
    display: grid;
    gap: $x3;
    padding: $x3;
    position: relative;
    align-content: flex-start;
    background: $white;
    box-shadow: $boxShadow;
  }

  &__separator {
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      position: relative;
      z-index: 10;
      background: $white;
      padding: 0 $x2;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      border-top: 1px solid $bgGray1;
    }
  }

  &__method {
    transition: $transition200;
    position: relative;
    z-index: 10;
    display: grid;
    gap: $x1;

    &--disabled {
      opacity: 0.2;
      cursor: not-allowed;

      >* {
        pointer-events: none;
      }
    }
  }

  &__loading-disclaimer {
    transition: $transition200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.9);
    z-index: 11;
    display: grid;
    justify-content: center;
    align-content: center;
    align-content: flex-start;
    padding: $x5 0;
    opacity: 0;
    pointer-events: none;

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__cards {
    img {
      max-width: 100%;
    }
  }

  &__terms {
    input {
      transform: translateY(2px);
    }

    label {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      gap: 3px;
    }
  }

  &__lightbox-terms {
    width: 640px;
    height: 480px;
    padding: $x3;
    display: grid;
    gap: $x3;
  }

  &--disabled {
    .pd-checkout-payment-options {

      &__method,
      &__separator {
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__wrapper {
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: auto 1fr;
      gap: $x4;
      padding: $x4;
    }

    &__cards,
    &__disclaimer,
    &__terms {
      grid-column: 1/4;
    }

    &__lightbox-terms {
      padding: $x4;
    }

    &__cards {
      display: grid;
      justify-content: center;

      img {
        max-width: 330px;
      }
    }

    &__separator {
      span {
        padding: $x3 0;
      }

      &:before {
        content: "";
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        border-top: 0;
        border-left: 1px solid $bgGray1;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}