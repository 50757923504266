@import '../../assets/scss/variables.scss';

.pd-initial-invite-checker {
  margin-bottom: $x3;

  &__wrapper {
    display: grid;
    grid-auto-flow: row;
    gap: $x2;
    justify-items: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
