@import '../../assets/scss/variables.scss';

.pd-checkout {
  display: grid;
  gap: $x3;

  &--saving {
    &:before {
      content: '';
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 100;
      cursor: progress;
    }
  }

  &__container {
    display: grid;
    gap: $x3;
    align-content: flex-start;
  }

  &.pd-page {
    padding-top: $x3 + $x3;
  }

  &__steps {
    position: relative;
  }

  &__step {
    transform: translateY(40px);
    transition: $transition200;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 10;

    &--active {
      transform: translateY(0);
      pointer-events: all;
      opacity: 1;
      position: relative;
      overflow: visible;
      z-index: 11;
    }
  }

  &__disclaimer {
    background: $bgNavy1;
    color: $white;
    padding: $x3;
    display: grid;
    gap: $x3;

    .pd-title-3 {
      color: $bgOrange1;
    }
  }

  &__customer-missed-deadline {
    display: grid;
    gap: $x2;

    .pd-button--text {
      display: inline-flex;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    gap: $x4;
    grid-template-columns: 1.4fr 1fr;
    min-height: 100%;

    &__container {
      gap: $x4;
    }

    &__summary {
      position: fixed;
      right: $x4;
      width: calc(100% / (2.4 * 1) - #{$x4 / 3 * 4});
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $layoutBreakpoint + $x4) {
    &__summary {
      width: calc(#{$layoutBreakpoint - $x4} / 2.4 * 1);
      right: calc((100% - #{$layoutBreakpoint + ($x4 / 2.4 * 1)}) / 2);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

.pd-checkout-module {
  display: grid;
  gap: $x3;

  &__header {
    display: grid;
    gap: $x1;
  }

  &__list {
    display: grid;
    gap: $x3;
  }

  &__list-item {
    display: grid;
    align-self: stretch;
    background: $white;
    box-shadow: $boxShadow;
  }

  &__nav {
    display: grid;
    justify-content: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

.pd-checkout-generic-disclaimer {
  display: grid;
  gap: $x3;
  position: relative;

  .pd-text .pd-button--text {
    display: inline;
  }

  .pd-button {
    justify-self: flex-start;
  }

  &__shipping-info {
    display: grid;
    gap: $x2;
    grid-template-columns: repeat(2, 1fr);
  }

  &__shipping-strategy {
    position: absolute;
    top: 0;
    right: 0;
  }
}
