@import "../../assets/scss/variables.scss";

.pd-backer-report {
  display: grid;
  grid-gap: $x3;

  &__nav {
    display: grid;
    grid-gap: $x2;
  }

  &__table {
    &__pledge-th {
      width: 90px;
    }

    &__ks-th {
      width: 60px;
    }

    &__status-th {
      width: 100px;
    }

    &__order-total-th {
      width: 115px;
    }
  }

  .pd-tip {
    justify-self: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__nav {
      grid-auto-flow: column;
      justify-content: space-between;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}