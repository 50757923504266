@import '../../assets/scss/variables.scss';

.pd-dashboard-pledge {

  @media screen and (min-width: $ipadBreakpoint) {
    .pd-tpl-dashboard {
      &__nav {
        top: 90px;
      }
    }
  }
}