@import '../../assets/scss/variables.scss';

.pd-meta-pagination {
  display: grid;
  grid-auto-flow: column;
  gap: $x2;
  justify-content: flex-start;
  align-items: center;

  &__status {
    color: $bgNavy1;
  }

  &__controls {
    border-left: 1px solid $gray3;
    display: grid;
    grid-auto-flow: column;
    gap: $x1;
    justify-content: flex-start;
    align-items: center;
    align-items: stretch;

    button {
      outline: 0;
      appearance: none;
      border: 0;
      background: transparent;
      cursor: pointer;

      &:disabled {
        opacity: .2;
      }

      .pd-icon {
        width: 13px;
        fill: $bgNavy1;
      }
    }

  }
}