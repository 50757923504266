@import '../../assets/scss/variables.scss';

.pd-dashboard-pledge-index {
  display: grid;
  gap: $x3;

  &__col {
    display: grid;
    gap: $x3;
    align-content: flex-start;
  }

  &__module {
    display: grid;
    gap: $x2;
    align-content: flex-start;
    align-self: flex-start;
  }

  &__password-reset {
    justify-items: flex-start;

    &__link {
      word-break: break-all;
      margin-bottom: $x2;
    }
  }

  &__manual-refunds-disclaimer {
    max-width: 77vw;
    display: grid;
    gap: $x2;

    ul {
      list-style: disc inside;
    }

    li {
      display: list-item;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 1fr 1.5fr;

    &__password-reset {
      justify-items: flex-start;
    }

    &__manual-refunds-disclaimer {
      max-width: 470px;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}