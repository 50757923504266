@import '../../assets/scss/variables.scss';

.pd-password-form {
  &__main {
    display: grid;
    gap: $x3;

    .pd-button {
      justify-self: flex-start;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @media screen and (min-width: $ipadBreakpoint) {
    .pd-profile-section__content {
    }
  }
}
