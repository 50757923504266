@import '../../assets/scss/variables.scss';

.pd-collaborators {
  display: grid;
  gap: $x2;

  &__table {
    &__company-th {
      width: 120px;
    }

    &__options-th {
      width: 120px;
      text-align: right;
    }

    &__member-th,
    &__member-td {
      width: 80px;
      text-align: center;
    }
  }

  &__actions {
    width: 130px;
    display: grid;
    grid-auto-flow: column;
    gap: $x2;
    justify-content: flex-end;
  }

  &__form {
    display: grid;
    gap: $x4;
    width: 600px;
    max-width: 100%;
    padding: $x3;
  }

  &__form-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    gap: $x2;
  }

  &__form-list-item {
    display: grid;
    gap: $x1;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  &__form-nav {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadLandscapeBreakpoint) {
    &__form-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
