@import '../../assets/scss/variables.scss';

.pd-account-pledges {
  &__container {
    display: grid;
    gap: $x3;
    padding: 0;
  }

  &__empty-state {
    margin: $x3;
  }

  &__loading {
    margin: $x3;
  }

  &__nav-view {
    display: grid;
    gap: $x2;
    grid-auto-flow: column;
    align-items: center;

    .pd-icon {
      cursor: pointer;
      opacity: .4;
      transition: $transition200;

      &:hover {
        fill: $bgCyan1;
        opacity: 1;
      }
    }
  }

  &__nav-view-as-table-icon {
    .pd-account-pledges--view-as-table & {
      opacity: 1;
    }
  }

  &__nav-view-as-grid-icon {
    .pd-account-pledges--view-as-grid & {
      opacity: 1;
    }
  }

  &__table {
    display: none;

    .pd-account-pledges--view-as-table & {
      display: block;
    }

    &__status-th, &__date-th {
      width: 150px;
    }

    &__order-total-th {
      width: 120px;
    }
  }

  &__list {
    gap: $x3;
    padding: $x3;
    display: none;

    .pd-account-pledges--view-as-grid & {
      display: grid;
    }

    &:hover {
      .pd-account-pledges__item {
        opacity: .7;
      }
    }
  }

  &__item {
    transition: $transition200;
    box-shadow: $boxShadow;
    transform: scale(1);

    &:hover {
      transform: scale(1.05);
      opacity: 1 !important;

      a {
        text-decoration: none;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__wrapper {
    padding: $x2;
    display: grid;
    gap: $x1;
    justify-content: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__list {
      gap: $x3;
      padding: $x3;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}