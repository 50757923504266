@import "../../assets/scss/variables.scss";

.pd-packing-slips {
  display: grid;
  gap: $x4;

  &__wrapper {
    padding-top: $x3;
    padding-bottom: $x4;
  }

  &__error-log {
    display: grid;
    gap: $x2;
    margin-top: $x3;
    padding-top: $x3;
    border-top: 1px solid $gray2;

    .pd-title-4 {
      margin-bottom: $x2;
    }

    p {
      font-size: 0.8em;
      font-family: Monaco, Menlo, "Courier New", monospace;
      line-height: 1.4em;
      color: $dGreen;
      padding-bottom: $x2;
      border-bottom: 1px solid $gray1;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}