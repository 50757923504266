@import "../../assets/scss/variables.scss";

.pd-transaction-history {
  display: grid;
  gap: $x2;

  &__table__gateway-td {
    a:hover {
      .pd-tip--error {
        text-decoration: $dRed underline;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__table {
      th:nth-child(1) {
        width: 160px;
      }

      th:nth-child(2) {
        width: 140px;
      }
    }
  }
}