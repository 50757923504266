@import "../../assets/scss/variables.scss";

.pd-checkout-product {
  display: grid;
  grid-template-rows: auto 1fr;

  &__image {
    max-width: 100%;
    cursor: pointer;
  }

  &__wrapper {
    gap: $x2;
    padding: $x3;
    display: grid;
    grid-template-rows: auto auto 1fr auto;

    .pd-text {
      white-space: pre-line;
    }

    .pd-button.pd-button--text-sm {
      justify-self: flex-start;
    }
  }
}