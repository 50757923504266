@import "../../assets/scss/variables.scss";

.pd-rewards-summary {
  display: grid;
  gap: $x4 * 2;

  &__item {
    display: grid;
    gap: $x2;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {

    &__table {
      &__name-th {
        width: 300px;
      }
    }
  }
}