@import '../../assets/scss/variables.scss';

.pd-loading-message {
  display: grid;
  grid-auto-flow: column;
  gap: $x2;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
}
