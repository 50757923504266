@import "../../assets/scss/variables.scss";

.pd-footer {
  display: grid;
  align-self: flex-end;
  background: $bgNavy1;
  color: $bgCyan1;

  &__image {
    max-width: 120px;
    fill: $bgCyan1;
  }

  &__figure {
    padding: $x4 $x3;
    max-width: $layoutBreakpoint;
    display: grid;
    gap: $x3;
    align-items: center;
    grid-template-columns: auto 1fr;
    justify-items: space-between;
  }

  &__social {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: $x2;
    margin: 0 0 $x4 $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;

    &__figure {
      padding: $x5 $x4;
      gap: $x4;
    }

    &__image {
      max-width: 200px;
    }

    &__social {
      margin: 0 $x4 0 0;
    }
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
