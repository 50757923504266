@import '../../assets/scss/variables.scss';

.pd-user-picker {
  display: grid;
  gap: $x2;

  &__create-form {
    display: grid;
    gap: $x2;
  }

  &__search {
    display: grid;
    gap: $x3;
  }

  &__search-field {
    display: grid;
    gap: $x1;
  }

  &__search-results {
    display: grid;
    gap: $x1;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__search-field {
      grid-template-columns: 1fr auto;
      align-items: flex-end;
    }

    &__create-form {
      grid-template-columns: 1fr 1fr auto;

      .pd-button {
        transform: translateY(20px);
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
