@import "../../assets/scss/variables.scss";

.pd-refund-form {
  display: grid;
  gap: $x2;

  &__form, &__unrefundable-disclaimer {
    display: grid;
    gap: $x2;

    .pd-button {
      justify-self: flex-start;
    }
  }

  &__confirmation {
    display: grid;
    grid-auto-flow: column;
    gap: $x2;
    align-items: center;
    background: rgba($dRed, .2);
    padding: $x1;
  }
}