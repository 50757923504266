@import './variables.scss';

.pd-label {
  @include pd-title-4;
  font-size: 0.85rem;
  font-weight: 500;
}

.pd-textfield {
  @include pd-text;
  transition: $transition200;
  font-size: 0.85rem;
  padding: 0.35rem;
  appearance: none;
  display: inline-block;
  border: 1px solid $gray1;
  border-collapse: collapse;
  border-radius: 0;
  overflow: hidden;
  outline: 0;

  &--xs {
    font-size: 0.75rem;
    padding: 0.25rem;
  }

  &:focus {
    border-color: $gray2;
    background-color: rgba($gray1, 0.4);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--glass {
    border: 1px solid transparent;
    color: $white;
    background: rgba($black, 0.1);

    &::placeholder {
      color: $gray1;
    }

    &:focus {
      color: $gray4;
      background-color: $white;
      border-color: transparent;

      &::placeholder {
        color: $gray3;
      }
    }
  }
}

.pd-select {
  @include pd-text-sm;
  transition: $transition200;
  padding: 0.35rem 2rem 0.35rem 0.35rem;
  border: 1px solid $gray1;
  background: $white;
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) 0.85em, calc(100% - 16px) 0.85em;
  background-size: 4px 4px, 4px 4px;
  background-repeat: no-repeat;
  outline: 0;

  &:focus {
    border-color: $gray2;
    background-color: rgba($gray1, 0.4);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.pd-radio-button {
  display: grid;
  gap: $x1;

  &__item {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: $x1;
    cursor: pointer;

    > * {
      cursor: pointer;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &--horizontal {
      grid-auto-flow: column;
      justify-content: flex-start;
      gap: $x4;
    }
  }
}

.pd-field {
  display: grid;
  grid-gap: $x1;
  align-content: flex-start;

  &__meta {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: $x2;
  }

  textarea {
    resize: none;
  }
}

.pd-field-error {
  color: $dRed;
}

.pd-checkbox-field {
  grid-template-columns: auto 1fr;
  grid-template-rows: none;

  .pd-label {
    user-select: none;
    cursor: pointer;
  }
}

.pd-checkbox {
  appearance: checkbox;

  &:disabled {
    cursor: not-allowed;
  }
}