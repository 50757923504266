// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// TYPOGRAPHY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$titillium: 'Titillium Web', sans-serif;
$dosis: 'Dosis', sans-serif;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// BREAKPOINTS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$ipadBreakpoint: 768px;
$ipadLandscapeBreakpoint: 1024px;
$desktopBreakpoint: 1200px;
$layoutBreakpoint: 1366px;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// COLOR PALLETE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$white: #ffffff;
$black: #111111;
$gray1: #eeeeee;
$gray2: #dddddd;
$gray3: #bbbbbb;
$gray4: #444444;

$dBlue: #007bff;
$dRed: #dc3545;
$dYellow: #ffc107;
$dGreen: #28a745;
$dCyan: #17a2b8;
$dSilver: #777;

$bgGray1: #e6e6e6;
$bgOrange1: #fe5d2c;
$bgCyan1: #5da8b9;
$bgNavy1: #15405c;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// TRANSITIONS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$transition200: all 200ms ease-in-out;
$boxShadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// REGULAR NUMBERS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$headerMobileHeight: 48px;
$headerDesktopHeight: 56px;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// MAGIC NUMBERS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$x1: 5px;
$x2: $x1 * 2;
$x3: $x2 * 2;
$x4: $x3 * 2;
$x5: $x4 * 2;
