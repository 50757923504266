@import "../../assets/scss/variables.scss";

.pd-new-hub-form {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'name name '
    'unit-length unit-weight'
    'formula factor-formula'
    'print-slip-cost charge-vat'
    'multiwave multiwave'
    'submit submit'
  ;

  &__name {
    grid-area: name;
  }

  &__unit-length {
    grid-area: unit-length;
  }

  &__unit-weight {
    grid-area: unit-weight;
  }

  &__print-slip-cost {
    grid-area: print-slip-cost;
  }

  &__charge-vat {
    grid-area: charge-vat;
  }

  &__multiwave {
    grid-area: multiwave;
  }

  &__formula {
    grid-area: formula;
  }

  &__factor-formula {
    grid-area: factor-formula;
  }

  &__submit {
    grid-area: submit;
    justify-self: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'name name unit-length unit-weight'
      'formula formula multiwave multiwave'
      'formula formula print-slip-cost print-slip-cost'
      'factor-formula factor-formula charge-vat charge-vat'
      'submit submit submit submit'
    ;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}