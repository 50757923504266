@import '../../assets/scss/variables.scss';

.pd-dashboard-project {
  &__visibility {
    transition: $transition200;
    display: grid;
    grid-auto-flow: row;
    justify-content: flex-start;
    gap: $x2;
    padding: $x2;
    border: 1px solid $dCyan;
    color: $dCyan;
    background: rgba($dCyan, 0.1);

    &--hidden {
      border: 1px solid $dYellow;
      color: darken($dYellow, 10%);
      background: rgba($dYellow, 0.1);
    }
  }

  &__close-cta {
    justify-self: flex-start;
  }
}
