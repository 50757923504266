@import '../../assets/scss/variables.scss';
@import '../../assets/scss/typography.scss';
@import '../../assets/scss/buttons.scss';

@keyframes basic-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pd-tab-panel {
  display: grid;

  &--with-title {
    grid-template-columns: auto 1fr;

    .pd-tab-panel__wrapper {
      grid-column: 1/3;
    }
  }

  &__title {
    display: grid;
    align-content: center;
    padding: 0 $x2 0 0;
    border-bottom: 3px solid transparent;

    .pd-tab-panel--navy & {
      border-color: $bgNavy1;
    }

    .pd-tab-panel--cyan & {
      border-color: $bgCyan1;
      color: $bgCyan1;
    }
  }

  &__nav {
    border-bottom: 3px solid transparent;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .pd-tab-panel--navy & {
      border-color: $bgNavy1;
    }

    .pd-tab-panel--cyan & {
      border-color: $bgCyan1;
    }
  }

  &__nav-list {
    transition: 300ms ease-out;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__nav-item {
    @include pd-button-base();
    display: flex;
    flex: 0 0 auto;
    justify-self: flex-start;
    padding: 0 $x3;
    position: relative;

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    span {
      z-index: 2;
    }

    &::before {
      transition: $transition200;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 0.2;
      }
    }

    .pd-tab-panel--navy & {
      color: $bgNavy1;

      &:before {
        background-image: linear-gradient(45deg, $bgNavy1 calc(100% - 10px), transparent calc(100% - 10px));
      }
    }

    .pd-tab-panel--cyan & {
      color: $bgCyan1;

      &:before {
        background-image: linear-gradient(45deg, $bgCyan1 calc(100% - 10px), transparent calc(100% - 10px));
      }
    }

    .pd-tab-panel--justify & {
      flex: 1 0 auto;
    }

    &--current {
      .pd-tab-panel--navy &,
      .pd-tab-panel--cyan & {
        color: $white;

        &:before {
          opacity: 1;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__item {
    animation: basic-show 900ms;
    display: none;

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    &--current {
      display: block;
    }
  }
}
