@import '../../assets/scss/variables.scss';

.pd-account-information {
  &__form {
    display: grid;
    gap: $x3;
  }

  &__nav {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: $x1;
  }

  &__req span {
    color: $bgCyan1;
    font-weight: bold;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {

    &__form {
      grid-template-columns: repeat(2, 1fr);
    }

    &__nav {
      grid-column: 1/3;
    }

  }
}