@import '../../assets/scss/variables.scss';

.pd-expansion-panel {
  position: relative;

  &__title {
    border-bottom: 1px solid transparent;
  }

  &__button.pd-button {
    margin-bottom: -1px;

    .pd-icon {
      margin-right: 0.85rem;
    }
  }

  &__content {
    transition: $transition200;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }

  &--expanded {
    > .pd-expansion-panel__title .pd-expansion-panel__button.pd-button .pd-icon {
      transform: rotateX(180deg);
    }

    > .pd-expansion-panel__content {
      opacity: 1;
      pointer-events: all;
    }
  }

  &--cyan {
    .pd-expansion-panel__title {
      border-color: $bgCyan1;
    }
  }
}