@import "../../assets/scss/variables.scss";

.pd-project-basics-form {
  &__main {
    display: grid;
    grid-gap: $x3;
    padding: $x3;
    background: $white;
    box-shadow: $boxShadow;
  }

  &__column,
  &__column-full {
    display: grid;
    grid-gap: $x3;
  }

  &__preview {
    background: $gray1;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    > img {
      width: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .pd-button {
    justify-self: flex-start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__main {
      grid-gap: $x4;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
    }

    &__column {
      grid-template-rows: auto 1fr;
      margin-bottom: 0;
    }

    &__column-full {
      grid-column: 1/3;
      grid-template-rows: auto 1fr;
      margin-bottom: 0;
    }
  }
}
