@import "~normalize.css";
@import url("https://fonts.googleapis.com/css?family=Dosis:600,700|Titillium+Web:400,700&display=swap");
@import "../assets/scss/variables.scss";
@import "../assets/scss/functions.scss";
@import "../assets/scss/typography.scss";
@import "../assets/scss/templates.scss";
@import "../assets/scss/forms.scss";
@import "../assets/scss/buttons.scss";
@import "../assets/scss/utilities.scss";
@import "../assets/scss/reset.scss";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-application {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;

  &--no-scroll {
    overflow: hidden;
  }

  &__offline-disclaimer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $bgCyan1;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: $x3;
    padding: $x3;

    .pd-text {
      color: $white;
      font-size: 1.2rem;
      line-height: 1.6rem;
      max-width: 340px;
      display: block;
    }
  }

  &__offline-icon {
    svg {
      width: auto;
      height: 50px;
      display: block;
      fill: $bgNavy1;
    }
  }

  &__offline-logo {
    position: absolute;
    top: $x3;
    left: $x3;

    svg {
      width: 100px;
      height: auto;
      display: block;
      fill: $bgNavy1;
    }
  }

  &__update {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    background: rgba($white, 0.9);
    display: grid;
    align-items: center;
    justify-content: center;

    &__disclaimer {
      margin: 0 $x3;
      padding: $x3;
      background: $bgCyan1;
      color: $white;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: $x1;
      box-shadow: $boxShadow;

      .pd-button {
        margin-left: $x3;
      }
    }

    &__disclaimer-icon {
      svg {
        width: auto;
        height: 22px;
        display: block;
        fill: $bgNavy1;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-rows: 1fr auto;
    padding-top: $headerDesktopHeight;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-page-full {
  min-height: 100%;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-page {
  max-width: $layoutBreakpoint;
  margin: 0 auto;
  padding: 0 $x3 $x3;

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    padding: 0 $x4 $x4;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $layoutBreakpoint + $x4) {
    padding: 0 0 $x4;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-container {
  max-width: $layoutBreakpoint;
  margin: 0 auto;
  padding: 0 $x3;

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    padding: 0 $x4;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $layoutBreakpoint + $x4) {
    padding: 0;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-form-box-page {
  display: grid;
  align-content: center;
  justify-content: center;
  min-height: 100%;
  max-width: 100%;

  &__header {
    display: grid;
    gap: $x2;
  }

  &__form {
    display: grid;
    align-content: center;
    gap: $x3;
    padding: $x4;
    max-width: 400px;
    background: $white;
    box-shadow: $boxShadow;
  }

  &__field-group {
    display: grid;
    grid-auto-flow: column;
    gap: $x3;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: $x2;
  }

  &__secondary-actions {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-floating-tooltip {
  transition: $transition200;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  transform: translateX(0);
  z-index: 21;

  &--active {
    opacity: 1;
    pointer-events: all;
  }

  &__curtain {
    z-index: 21;
    display: block;
    position: fixed;
    width: 270px;
    height: 310px;
    top: 5px;
    right: 5px;
    box-shadow: 0px 0px 0 100vh rgba(0, 0, 0, 0.7);

    @media screen and (min-width: $ipadBreakpoint) {
      top: $x1;
      right: $x1;
      box-shadow: 0px 0px 0 100vw rgba(0, 0, 0, 0.7);
    }

    @media screen and (min-width: $layoutBreakpoint + $x4) {
      right: calc((100vw - #{$layoutBreakpoint + ($x4*2)}) / 2);
      width: 280px;
    }
  }

  &__container {
    display: grid;
    gap: $x2;
    justify-items: flex-start;
    padding: $x3;
    max-width: 300px;
    position: fixed;
    z-index: 21;
    top: 330px;
    right: 10px;
    background: rawColor(rgba($dCyan, 0.1));
    border-radius: $x1;
    box-shadow: $boxShadow;

    .pd-title-4 {
      color: $bgCyan1;
      // font-weight: 700;
    }

    &:after {
      content: '';
      display: block;
      width: $x3;
      height: $x2;
      background: rawColor(rgba($dCyan, 0.1));
      position: absolute;
      top: 0;
      right: $x1;
    }

    &:before {
      content: '';
      display: block;
      width: $x2;
      height: $x2;
      background: rawColor(rgba($dCyan, 0.1));
      box-shadow: $boxShadow;
      position: absolute;
      top: 0;
      right: 0;
      transform-origin: center center;
      transform: rotate(45deg) translateX(-105%) translateY(30%);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    @media screen and (min-width: $layoutBreakpoint + $x4) {
      right: calc((100vw - #{$layoutBreakpoint + ($x4*2)}) / 2);
    }
  }
}