@import '../../assets/scss/variables.scss';

.pd-pledge-editor {
  display: grid;
  gap: $x4;

  &__user-form,
  &__user-form-wrapper,
  &__base-pledge,
  &__additional-buys {
    display: grid;
    gap: $x2;
  }

  &__pledge-form {
    display: grid;
    gap: $x3;
  }

  &__base-pledge-item,
  &__include-address {
    display: grid;
    gap: $x1;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  &__base-pledge-list,
  &__additional-buys-list {
    display: grid;
    gap: $x2;
  }

  &__additional-buys-item {
    display: grid;
    gap: $x1;
    grid-template-columns: 40px 1fr;
    align-items: center;
  }

  &__address-fields {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: $x3;
    border-top: 1px solid $bgGray1;
    padding-top: $x3;
  }

  &__field-calc-shipping,
  &__field-name,
  &__field-address-line-1,
  &__field-address-line-2,
  &__field-province,
  &__field-postal-code,
  &__field-city,
  &__field-postal-code,
  &__field-complement,
  &__field-country {
    grid-column: 1/7;
  }

  &__field-phone {
    grid-column: 1/4;
  }

  &__field-taxpayer-id {
    grid-column: 4/7;
  }

  &__disclaimer-wrapper {
    display: grid;
    gap: $x1;
    justify-items: flex-start;
  }

  &__user-form-wrapper {
    display: grid;
    gap: $x3;
  }

  &__field-wrapper {
    display: grid;
    gap: $x1;
    grid-template-columns: 1fr auto;

    .pd-button {
      align-self: flex-end;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__pledge-form {
      grid-template-columns: repeat(1fr, 3);
      align-items: flex-start;
    }

    &__base-pledge {
      grid-column: 1/2;
    }

    &__additional-buys {
      grid-column: 2/3;
    }

    &__include-address {
      grid-column: 1/4;
    }

    &__save {
      grid-column: 1/4;
      justify-self: flex-start;
    }

    &__address-fields {
      // padding-top: 0;
      // border-top: 0 solid #000;
      grid-column: 1/4;
      grid-template-columns: repeat(8, 1fr);
    }

    &__field-calc-shipping,
    &__field-name,
    &__field-address-line-1 {
      grid-column: 1/5;
    }

    &__field-address-line-2 {
      grid-column: 5/9;
    }

    &__field-postal-code {
      grid-column: 1/4;
    }

    &__field-complement {
      grid-column: 4/9;
    }

    &__field-phone {
      grid-column: 5/7;
    }

    &__field-taxpayer-id {
      grid-column: 7/9;
    }

    &__field-country {
      grid-column: 1/4;
    }

    &__field-province {
      grid-column: 4/7;
    }

    &__field-city {
      grid-column: 7/9;
    }
  }
}
