@import '../../assets/scss/variables.scss';

.pd-profile {
  display: grid;
  gap: $x3;

  &__content {
    display: grid;
    gap: $x3;
  }

  &__confirmation {
    display: grid;
    gap: $x1;
  }

  &.pd-page {
    padding-top: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    gap: $x4;

    &__content {
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      gap: $x4;
    }

    &__confirmation {
      grid-column: 1/3;
      gap: $x3;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
    }

    &.pd-page {
      padding-top: $x4;
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-profile-section {
  display: grid;
  gap: $x3;
  background: $white;
  box-shadow: $boxShadow;
  padding: $x3;

  &__content {
    transition: $transition200;
    display: grid;
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    padding: $x4;
  }
}
