@import '../../assets/scss/variables.scss';

.pd-pledge-transferer {
  display: grid;
  gap: $x3;

  &__confirmation {
    display: grid;
    gap: $x3;
    justify-content: flex-start;
  }

  &__confirmation-alert {
    display: grid;
    gap: $x1;
    justify-content: flex-start;
  }
}
