@import '../../assets/scss/variables.scss';

.pd-new-hub-import-form {
  grid-template-columns: 1;
  grid-template-rows: auto;
  grid-template-areas:
    'strategy'
    'import-type'
  ;

  &__new-hub-fields {
    grid-area: new-hub-fields;
    display: none;
  }

  &__strategy {
    grid-area: strategy;
  }

  &__import-type {
    grid-area: import-type;
  }

  &__file {
    grid-area: file;
  }

  &__hub {
    grid-area: hub;
  }

  &__country {
    grid-area: country;
  }

  &__name {
    grid-area: name;
  }

  &__unit-length {
    grid-area: unit-length;
  }

  &__unit-weight {
    grid-area: unit-weight;
  }

  &__print-slip-cost {
    grid-area: print-slip-cost;
  }

  &__charge-vat {
    grid-area: charge-vat;
  }

  &__formula {
    grid-area: formula;
  }

  &__multiwave {
    grid-area: multiwave;
  }

  &__factor-formula {
    grid-area: factor-formula;
  }

  &__submit {
    grid-area: submit;
  }

  .pd-tpl-lightbox-form__required {
    grid-area: required;
  }

  &__submit.pd-button, &__separator, .pd-tpl-lightbox-form__required {
    display: none;
  }

  &__file, &__hub, &__country {
    &.pd-field {
      display: none;
    }
  }

  &--new-hub {
    grid-template-areas:
      'strategy strategy'
      'import-type file'
      'hub country'
      'new-hub-fields new-hub-fields'
      'submit required'
    ;

    .pd-new-hub-import-form {
      &__new-hub-fields {
        display: grid;
      }
    }
  }

  &--overwrite {
    grid-template-areas: 'strategy strategy'
      'import-type file'
      'hub country'
      'submit required'
    ;
  }

  &--new-hub, &--overwrite {
    grid-template-columns: repeat(2, 1fr);

    .pd-new-hub-import-form {
      &__submit.pd-button, &__separator {
        display: block;
      }

      &__file, &__hub {
        &.pd-field {
          display: grid;
        }
      }
    }

    .pd-tpl-lightbox-form__required {
      display: block;
    }
  }

  &--states,
  &--postal-codes,
  &--mixed {
    .pd-new-hub-import-form {
      &__country {
        &.pd-field {
          display: grid;
        }
      }
    }
  }

  &__new-hub-fields {
    border-top: 1px solid $gray1;
    margin-top: $x3;
    padding-top: $x3 * 2;
    gap: $x3;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'name name'
      'unit-length unit-weight'
      'formula factor-formula'
      'print-slip-cost charge-vat'
      'multiwave multiwave'
      'submit submit'
    ;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-areas: 'strategy import-type';

    &--new-hub {
      grid-template-areas:
        'strategy import-type'
        'hub file'
        'country country'
        'new-hub-fields new-hub-fields'
        'submit required'
      ;
    }

    &--overwrite {
      grid-template-areas:
        'strategy import-type'
        'hub file'
        'country country'
        'submit required'
      ;
    }

    &__new-hub-fields {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        'name name unit-length unit-weight'
        'formula formula multiwave multiwave'
        'formula formula print-slip-cost print-slip-cost'
        'factor-formula factor-formula charge-vat charge-vat'
        'submit submit submit submit'
      ;
    }
  }
}