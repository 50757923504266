@import "../../assets/scss/variables.scss";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pd-header {
  display: grid;
  align-self: flex-start;
  position: relative;
  z-index: 20;
  background: $bgCyan1;
  box-shadow: 0 0 $x1 rgba($black, 0.14), 0 $x1 $x2 rgba($black, 0.28);

  &__quick-action-tooltip {
    display: grid;
    gap: $x2;
    width: 200px;
    padding: $x1 0;
  }

  .pd-container {
    display: grid;
    width: 100%;
    gap: $x2;
    grid-template-columns: auto 1fr auto;
    height: $headerMobileHeight;
  }

  &__logo {
    display: grid;
    justify-self: flex-start;
    align-items: center;

    figure {
      display: grid;
      gap: $x2;
      align-items: center;
      grid-auto-flow: column;
      color: $white;
    }

    figcaption {
      display: none;
    }

    svg {
      height: $headerMobileHeight - $x3;
      width: auto;
      display: block;
      fill: $bgNavy1;
    }
  }

  &__admin-user {
    display: grid;
    grid-gap: $x2;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  &__logged-user {
    display: grid;
    grid-gap: $x2;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-end;
  }

  &__btn-menu {
    appearance: none;
    border: 0;
    background-color: transparent;
    display: grid;
    align-self: stretch;
    align-items: center;
    outline: 0;
    cursor: pointer;

    svg {
      fill: $white;
    }
  }

  &__menu {
    transition: $transition200;
    position: absolute;
    top: $headerMobileHeight;
    right: $x3;
    background: $white;
    box-shadow: $boxShadow;
    padding: $x3;
    display: grid;
    gap: $x3;
    justify-items: flex-start;
    opacity: 0;
    pointer-events: none;

    &--opened {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__menu-header {
    color: $bgNavy1;
    justify-self: stretch;
    border-bottom: 1px solid rgba($bgNavy1, .2);
    padding-bottom: $x3;

    .pd-text-sm {
      color: $gray4;
    }
  }

  &__menu-list {
    display: grid;
    gap: $x2;
    justify-items: flex-start;
    border-bottom: 1px solid rgba($bgNavy1, .2);
    padding-bottom: $x3;
  }

  &__nav {
    display: none;
  }

  .pd-application--staging & {
    background: $bgOrange1;

    &__logo {
      display: grid;
      grid-auto-flow: column;

      svg {
        fill: $white;
      }

      &:after {
        content: 'Staging env';
        margin-left: $x2;
        color: $white;
        font-family: $dosis;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    position: fixed;
    top: 0;
    width: 100%;

    .pd-container {
      height: $headerDesktopHeight;
    }

    &__logo {
      &:hover {
        text-decoration: none;
      }

      img {
        height: $headerDesktopHeight - $x3;
      }

      figcaption {
        padding: $x1 $x2;
        display: inline-block;
        border-left: 1px solid rgba($black, 0.1);
      }
    }

    &__admin-user {
      justify-content: flex-end;
      grid-template-columns: 170px auto;
    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $layoutBreakpoint + $x4) {
    &__menu {
      right: calc((100vw - #{$layoutBreakpoint + $x4}) / 2);
    }
  }

}

.pd-dinamic-tooltip {
  // background: $bgNavy1 !important;

  &:after {
    // border-bottom-color: $bgNavy1 !important;
  }

  &.show {
    opacity: 1 !important;
  }
}