@import '../../assets/scss/variables.scss';

@keyframes disclaimer-show {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pd-disclaimers {
  position: fixed;
  bottom: $x3;
  right: $x3;
  z-index: 100;
  display: grid;
  justify-items: flex-end;
  grid-auto-flow: row-reverse;
  gap: $x3;
  pointer-events: none;
}

.pd-disclaimer {
  pointer-events: all;
  animation: disclaimer-show 300ms;
  transform-origin: 50% 100%;
  padding: $x3;
  max-width: calc(100vw - #{$x4});
  border-radius: $x1;
  font-weight: 500;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: $x2;

  .pd-icon {
    fill: $white;
  }

  .pd-text {
    white-space: pre-line;
  }

  &--hidden {
    transition: $transition200;
    height: 0;
    overflow: hidden;
  }

  &--success {
    background: $dGreen;
    color: $white;
    color: lighten($dGreen, 50%);
  }

  &--warning {
    background: $dYellow;
    color: darken($dYellow, 30%);

    .pd-icon {
      fill: $gray4;
    }
  }

  &--error {
    background: $dRed;
    color: $white;
    color: lighten($dRed, 35%);
  }

  &--info {
    background: $dCyan;
    color: $white;
    color: lighten($dCyan, 50%);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    max-width: 420px;
    align-content: center;
    align-items: center;
    gap: $x3;

    .pd-icon {
      width: 32px;
    }
  }
}
