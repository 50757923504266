@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';

.pd-account {
  display: grid;
  gap: $x3;
  height: 100%;
  align-content: flex-start;

  &.pd-page {
    padding-top: $x3 * 2;
  }

  &__confirmation-disclaimer {
    display: grid;
    gap: $x2;
  }

  &__nav-item {
    display: grid;
    height: $x4;
    border-bottom: 1px solid $gray3;

    &:last-child {
      border: 0;
    }

    .pd-button {
      justify-content: flex-start;
      padding-left: $x2;
    }

  }

  &__section {
    display: grid;
    border-top: 3px solid $bgNavy1;
  }

  &__header {
    color: $bgNavy1;
    padding: $x3;
    background: rawColor(rgba($bgNavy1, 0.2));
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: $x2;
    align-items: center;
    z-index: 1;

    .pd-icon {
      fill: $bgNavy1;
    }
  }

  &__container {
    padding: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    grid-template-columns: 270px auto;
    gap: $x4;

    &__confirmation-disclaimer {
      grid-column: 1/3;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
    }

    &__nav, &__section {
      align-self: stretch;
    }

    &.pd-page {
      max-height: calc(100vh - #{ $headerDesktopHeight });
    }

    &__section {
      overflow-y: auto;
      align-content: flex-start;
    }

  }

}