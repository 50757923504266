@import "../../assets/scss/variables.scss";

@keyframes wrapper-show {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes wrapper-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes curtain-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes curtain-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.pd-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 90;
  display: grid;
  justify-content: center;
  align-content: center;
  pointer-events: none;

  &__wrapper,
  &__curtain {
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  &__wrapper {
    animation-name: wrapper-hide;
    z-index: 90;
    transform: scale(1);
    transform-origin: 50% 100%;
    margin: $x1;
    max-width: calc(100vw - (#{$x1} * 2));
    max-height: calc(100vh - (#{$x1} * 2));
    background: $white;

    > * {
      max-width: 100%;
      max-height: 100%;
      transform: translateX(0);
    }
  }

  &__curtain {
    animation-name: curtain-hide;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 89;
    background: rgba($black, 0.6);
    cursor: pointer;
  }

  &__close {
    position: fixed;
    z-index: 91;
    top: $x2;
    right: $x2;
    font-size: 1rem;
    width: $x3;
    height: $x3;
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    outline: 0;
    cursor: pointer;

    &:before {
      content: "✖️";
    }
  }

  &--active {
    pointer-events: all;

    .pd-lightbox {
      &__wrapper {
        animation-name: wrapper-show;
      }

      &__curtain {
        animation-name: curtain-show;
      }
    }
  }

  &--locked {
    .pd-lightbox {
      &__curtain,
      &__close {
        cursor: wait;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__wrapper {
      margin: $x3;
      max-width: calc(100vw - (#{$x3} * 2));
      max-height: calc(100vh - (#{$x3} * 2));
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
