@import "../../assets/scss/variables.scss";

.pd-address-decoder-field {
  display: grid;
  gap: $x1;

  &__row {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: $x2;
  }

  &__mini-map {
    img {
      max-width: 100%;
    }
  }

  &__results {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: $x3;
    width: 400px;
    height: 300px;

    ul {
      display: grid;
      align-content: flex-start;
      gap: $x2;
      text-align: left;
    }

    .pd-button {
      white-space: pre-line;
      text-align: left;
      line-height: 0.9rem;
    }
  }
}
