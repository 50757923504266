@import "./variables.scss";

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
/>  TYPOS
/ - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/

.pd-title-1 {
  font-family: $dosis;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.pd-title-2 {
  font-family: $dosis;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 700;
}

@mixin pd-title-3 {
  font-family: $dosis;
  font-size: 1.1rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  font-weight: 600;
}

.pd-title-3 {
  @include pd-title-3();
}

@mixin pd-title-4 {
  font-family: $dosis;
  font-size: 0.9rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  font-weight: 700;
}

.pd-title-4 {
  @include pd-title-4();
}

@mixin pd-text {
  font-family: $titillium;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: normal;

  strong {
    font-weight: 700;
  }
}

.pd-text {
  @include pd-text();
}

@mixin pd-text-sm {
  font-family: $titillium;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: normal;
  text-transform: none;
}

.pd-text-sm {
  @include pd-text-sm();
}

@mixin pd-text-xs {
  font-family: $titillium;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9rem;
  letter-spacing: normal;
  text-transform: none;
}

.pd-text-xs {
  @include pd-text-xs();
}

strong.pd-text,
strong.pd-text-sm,
.pd-text strong,
.pd-text-sm strong {
  font-weight: 700;
}

.pd-text--error {
  color: $dRed;
}

.pd-text--info {
  color: $dBlue;
}

.pd-text--tip {
  color: $dSilver;
}

.pd-code {
  font-family: 'Monaco', 'Lucida Console', 'Courier New', Courier, monospace;
  letter-spacing: .13em;

  &--link {
    transition: $transition200;
    text-decoration: underline;

    &:hover {
      background: rgba($bgNavy1, 0.2);
    }
  }

  &--navy {
    color: $bgNavy1;
  }
}