@import '../../assets/scss/variables.scss';

.pd-shipping-cost-simulator {
  display: grid;
  gap: $x3;

  &__other,
  &__form {
    display: grid;
    gap: $x3
  }

  &__bundles,
  &__add-ons {
    display: grid;
    gap: $x1
  }

  &__item {
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    gap: $x2;

    input {
      text-align: center;
    }
  }

  &__json pre {
    font-size: 0.8em;
    font-family: Monaco, Menlo, "Courier New", monospace;
    line-height: 1.4em;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__form {
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;
    }

    &__title {
      grid-column: 1/4;
    }
  }

}