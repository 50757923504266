@import '../../assets/scss/variables.scss';

.pd-image-upload-field {
  &__preview {
    background: $gray1;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    >img {
      width: 100%;
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}