@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';

.pd-company-projects-list {
  background: $dCyan;
  background: linear-gradient(180deg, $dCyan 260px, transparent 260px);
  padding: $x4 0;

  &__container {
    display: grid;
    gap: $x4;
  }

  &__list {
    display: grid;
    gap: $x3;
  }

  &__item {
    display: grid;
    box-shadow: $boxShadow;
    background: $white;

    img {
      max-width: 100%;
    }
  }

  &__link {
    display: grid;
    align-content: flex-start;

    &:hover {
      text-decoration: none;
    }
  }

  &__wrapper {
    display: grid;
    gap: $x1;
    padding: $x3;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    padding: $x5 0;

    &__container {
      gap: $x5;
    }

    &__list {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $x4;
    }

    &__item {
      transition: $transition200;
      transform: scale(1);

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}