@mixin pd-button-base {
  transition: $transition200;
  appearance: none;
  font-family: $titillium;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  padding: 0.35rem 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  color: inherit;
  outline: 0;
  height: 32px;
}

.pd-button {
  @include pd-button-base();
  border: 1px solid $gray1;
  border-collapse: collapse;
  border-radius: 0;

  &:hover {
    text-decoration: none;
  }

  &--text {
    @include pd-text();
  }

  &--text-sm {
    @include pd-text-sm();
  }

  &--text,
  &--text-sm {
    text-transform: initial;
    padding: 0 0.15rem;
    height: auto;
    border: 0;
  }

  &--text-inline {
    padding: 0 0;
    text-decoration: underline;
    display: inline;

    &:hover {
      text-decoration: underline;
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }

  &--primary {
    border: 1px solid $dBlue;
    color: $white;
    background: $dBlue;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dBlue;
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover {
      color: $dBlue;
      background: rgba($dBlue, 0.2);

      .pd-icon {
        fill: $dBlue;
      }
    }
  }

  &--secondary {
    border: 1px solid $gray4;
    color: $gray1;
    background: $gray4;

    .pd-icon {
      fill: $gray1;
    }

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $gray4;
      background: transparent;

      .pd-icon {
        fill: $gray4;
      }
    }


    &:hover {
      color: $gray4;
      background: $gray2;

      .pd-icon {
        fill: $gray4;
      }
    }
  }

  &--light {
    border: 1px solid $gray3;
    color: $gray4;
    background: $gray1;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $gray3;
      background: transparent;
    }

    .pd-icon {
      fill: $gray4;
    }

    &:hover {
      background: $gray2;
    }
  }

  &--glass,
  &--glass-dark {
    border: 1px solid transparent;
    color: $white;
    background: rgba($black, 0.1);

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: rgba($black, 0.1);
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover,
    &.pd-button--active {
      background: $white;
      color: $gray4;

      .pd-icon {
        fill: $gray4;
      }
    }
  }

  &--glass-dark {
    color: $gray4;

    .pd-icon {
      fill: $gray4;
    }
  }

  &--cyan {
    border: 1px solid $bgCyan1;
    color: $white;
    background: $bgCyan1;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dCyan;
      background: transparent;

      .pd-icon {
        fill: $bgCyan1;
      }
    }

    .pd-icon {
      fill: $white;
    }

    &:hover,
    &.pd-button--active {
      color: $bgCyan1;
      background: rgba($bgCyan1, 0.2);

      .pd-icon {
        fill: $bgCyan1;
      }
    }
  }

  &--navy {
    border: 1px solid $bgNavy1;
    color: $white;
    background: $bgNavy1;

    .pd-icon {
      fill: $white;
    }

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $bgNavy1;
      background: transparent;

      .pd-icon {
        fill: $bgNavy1;
      }
    }

    &:hover,
    &.pd-button--active {
      color: $bgNavy1;
      background: rgba($bgNavy1, 0.2);

      .pd-icon {
        fill: $bgNavy1;
      }
    }
  }

  &--success {
    border: 1px solid $dGreen;
    color: $white;
    background: $dGreen;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dGreen;
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover {
      color: $dGreen;
      background: rgba($dGreen, 0.2);

      .pd-icon {
        fill: $dGreen;
      }
    }
  }

  &--warning {
    border: 1px solid darken($dYellow, 10%);
    color: $white;
    background: darken($dYellow, 10%);

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dYellow;
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover {
      border-color: darken($dYellow, 10%);
      color: darken($dYellow, 10%);
      background: rgba($dYellow, 0.2);

      .pd-icon {
        fill: darken($dYellow, 10%);
      }
    }
  }

  &--danger {
    border: 1px solid $dRed;
    color: $white;
    background: $dRed;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dRed;
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover {
      color: $dRed;
      background: rgba($dRed, 0.2);

      .pd-icon {
        fill: $dRed;
      }
    }
  }

  &--info {
    border: 1px solid $dCyan;
    color: $white;
    background: $dCyan;

    &.pd-button--text,
    &.pd-button--text-sm {
      border: 0;
      color: $dCyan;
      background: transparent;
    }

    .pd-icon {
      fill: $white;
    }

    &:hover {
      color: $dCyan;
      background: rgba($dCyan, 0.2);

      .pd-icon {
        fill: $dCyan;
      }
    }
  }

  &--link {
    border: 1px solid transparent;
    color: $gray4;
    background: transparent;
    padding: 0.35rem 0;

    .pd-icon {
      fill: $gray4;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &--icon {
    .pd-icon {
      transition: $transition200;
      width: 14px;
      margin-right: $x1;
    }
  }

  &--icon-after {
    .pd-icon {
      margin-left: $x1;
      margin-right: 0;
    }
  }

  &--icon-only {
    .pd-icon {
      margin: 0;
    }
  }

  &--icon-mobile {
    @media screen and (max-width: $ipadBreakpoint - 1) {
      span {
        display: none;
      }

      .pd-icon {
        width: calc(14px + (#{$x1} * 2));
        padding: 0 $x1;
        margin: 0;
      }
    }
  }

  &--mini {
    font-size: 0.65rem;
    font-weight: 700;
    padding: 0.25rem 0.25rem;
    height: 19px;

    .pd-icon {
      width: 9px;
    }
  }
}