@import '../../assets/scss/variables.scss';

.pd-cancel-and-refund-form {
  display: grid;
  gap: $x2;

  &__form,
  &__unrefundable-disclaimer {
    display: grid;
    gap: $x2;

    .pd-button {
      justify-self: flex-start;
    }
  }

  &__confirmation {
    display: grid;
    grid-auto-flow: column;
    gap: $x2;
    align-items: center;
    background: rgba($dRed, 0.2);
    padding: $x1;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadLandscapeBreakpoint) {
    &__form {
      grid-template-columns: repeat(4, 1fr);
    }

    &__reason-field,
    &__disclaimer {
      grid-column: 1/5;
    }

    &__refund-field {
      grid-column: 1/1;
    }

    &__waive-field {
      grid-column: 2/5;
    }

    &__submit {
      grid-column: 1/5;
      justify-self: flex-start;
    }
  }
}
