@import '../../assets/scss/variables.scss';

.pd-shipping-wave-management {
  display: grid;
  gap: $x3;

  &__main {
    display: grid;
    gap: $x3;
  }

  &__progression-disclaimer {
    display: grid;
    gap: $x1;
    justify-items: flex-start;
  }

  &__submit {
    justify-self: start;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  @media screen and (min-width: $ipadBreakpoint) {
    &__main {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        'multiplier multiplier allow allow'
        'single-wave single-wave multiwave multiwave'
        'submit . . .';
    }

    &__field-multiplier {
      grid-area: multiplier;
    }

    &__field-allow-multiwave-selection {
      grid-area: allow;
    }

    &__field-single-wave {
      grid-area: single-wave;
    }

    &__field-multiwave {
      grid-area: multiwave;
    }

    &__submit {
      grid-area: submit;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}
