@import '../../assets/scss/variables.scss';

.pd-dashboard-gateway {
  &__container {
    display: grid;
    gap: $x3;
  }

  @media screen and (min-width: $ipadBreakpoint) {
    &__container {
      grid-template-columns: 1.2fr 1fr 1fr;
      grid-template-areas:
        'user-search project-list project-list'
        'pledge-search project-list project-list'
        '. project-list project-list'
        'collaborators-list collaborators-list collaborators-list';
      align-items: flex-start;
      gap: $x4;
    }

    &__user-search {
      grid-area: user-search;
    }

    &__pledge-search {
      grid-area: pledge-search;
    }

    &__project-list {
      grid-area: project-list;
    }

    &__collaborators-list {
      grid-area: collaborators-list;
    }
  }
}
